'use strict';

const inherits = require('inherits');
const Buffer = require('safer-buffer').Buffer;
const DERDecoder = require('./der');
function PEMDecoder(entity) {
  DERDecoder.call(this, entity);
  this.enc = 'pem';
}
inherits(PEMDecoder, DERDecoder);
module.exports = PEMDecoder;
PEMDecoder.prototype.decode = function decode(data, options) {
  const lines = data.toString().split(/[\r\n]+/g);
  const label = options.label.toUpperCase();
  const re = /^-----(BEGIN|END) ([^-]+)-----$/;
  let start = -1;
  let end = -1;
  for (let i = 0; i < lines.length; i++) {
    const match = lines[i].match(re);
    if (match === null) continue;
    if (match[2] !== label) continue;
    if (start === -1) {
      if (match[1] !== 'BEGIN') break;
      start = i;
    } else {
      if (match[1] !== 'END') break;
      end = i;
      break;
    }
  }
  if (start === -1 || end === -1) throw new Error('PEM section not found for: ' + label);
  const base64 = lines.slice(start + 1, end).join('');
  // Remove excessive symbols
  base64.replace(/[^a-z0-9+/=]+/gi, '');
  const input = Buffer.from(base64, 'base64');
  return DERDecoder.prototype.decode.call(this, input, options);
};